import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomerLogos from "../components/CustomerLogos"
import WestSummary from "../components/WestSummary"
import AopSummary from "../components/AopSummary"
import Resources from "../components/Resources"
import Navegando from "../components/Navegando"
import Testimonals from "../components/Testimonials"
import Announcement from "../components/Announcement"
import CertSummary from "../components/CertSummary"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home - Pluralistic Networks"
        description="Build skills to become effective teams in a pluralistic, diverse, and remote working enviroment."
      />

      <div class="maxWidth" style={{ marginTop: "75px" }}>
        <img
          class="homeImage"
          src="/images/hero-illustration-combined.png"
          alt="connected networks of people"
        />
      </div>
      <section class="topMarginHome ">
        <div class="maxWidth">
          <div class="pagePadding topMarginHome2">
            <div class="pageTitle"> Pluralistic </div>
            <div class=" pageSub">networks</div>
            <div style={{ marginTop: "42px" }} class="biggerText">
              The world is increasingly unsettled, virtual and diverse.
              <br />
              We help you build the skills required to be effective in it.
            </div>
            <div class="centered">
              <button style={{ marginTop: "32px" }} class="contactButton">
                <Link to="/contact">Contact us today</Link>
              </button>
            </div>
            <div
              style={{
                marginTop: "86px",
                marginBottom: "12px",
                textAlign: "center",
              }}
              class=" ourCustomerTitle"
            >
              Our customers are individuals, companies and universities,
              including:
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginBottom: "5px" }} class="topLine">
        &nbsp;
      </div>
      <div>
        <CustomerLogos />
      </div>
      <div style={{ marginTop: "5px" }} class="bottomLine">
        &nbsp;
      </div>
      <div class="maxWidth">
        <div class="pagePadding">
          <WestSummary mt="64px" />
          <CertSummary mt="64px" />
          <div class="topLine">&nbsp;</div>
          <Resources mt="64px" />
        </div>
      </div>
      <div class="topLine">&nbsp;</div>
    </Layout>
  )
}

export default IndexPage
