import React from "react"

const CustomerLogos = () => (
  <div className="customerBoxOuter">
    <div class="customerBox" style={{ paddingTop: "5px", marginTop: "5px" }}>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="Salesforce"
          src="/images/companies/salesforce.png"
        />
      </div>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="cisco"
          src="/images/companies/cisco.png"
        />
      </div>

      <div class="custItem">
        <img
          class="companyLogo"
          alt="us navy"
          src="/images/companies/us-navy.png"
        />
      </div>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="university of colorado"
          src="/images/companies/u-of-c.png"
        />
      </div>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="Grupo sura"
          src="/images/companies/grupo-sura.png"
        />
      </div>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="catholic university of chile"
          src="/images/companies/uc-of-chile.png"
        />
      </div>
      <div class="custItem">
        <img
          class="companyLogo"
          alt="skanska"
          src="/images/companies/skanska.png"
        />
      </div>
    </div>
  </div>
)

export default CustomerLogos
