import React from "react"
import { Link } from "gatsby"

const CertSummary = ({ mt }) => (
  <section style={{ marginTop: `${mt}`, marginBottom: "67px" }}>
    <div class="topLine">&nbsp;</div>
    <div
      style={{
        textAlign: "center",
        marginTop: "8px",
      }}
      class="ourCustomerTitle"
    >
      A new orientation to building customer relationships
    </div>
    <div style={{ marginTop: "5px", marginBottom: "44px" }} class="bottomLine">
      &nbsp;
    </div>
    <div style={{ textAlign: "center" }}>
      <span class="pageSub2">Basic Skills for </span>
      <span class="pageTitle2">Navigating Moods</span>
    </div>
    <div style={{ marginTop: "36px" }} class=" bigText">
      We are living through a historic period of what feels like unprecedented
      uncertainty. In moments like this, restrictive moods and emotions can trap
      us in interpretations of the world that limit our ability to see
      possibilities.
      <br />
      <br />
      Our Navigating Moods (NM) Certification program is an opportunity for
      coaches – and subsequently their client(s) – to learn how to better
      navigate these moods and emotions. &nbsp;
      <a
        class="gotoLink"
        href="https://programs.pluralisticnetworks.com/moods-certifcation"
      >
        Learn more about the NM certification
      </a>
    </div>
    &nbsp;
  </section>
)

export default CertSummary
