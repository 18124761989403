import React from "react"
import Testimonials from "./Testimonials"

function AllTestimonials() {
  return (
    <>
      <div style={{ marginTop: "64px" }} class="topLine">
        &nbsp;
      </div>

      <div
        style={{
          textAlign: "center",
          marginTop: "8px",
        }}
        class="ourCustomerTitle"
      >
        Here's what WEST alumni are saying about WEST:
      </div>
      <div
        style={{ marginTop: "5px", marginBottom: "44px" }}
        class="bottomLine"
      >
        &nbsp;
      </div>
      <div class="columns is-multiline">
        <div class="column is-one-third">
          <Testimonials
            text="Having spent more than 25 years in the field of training and education, both corporate and public K-12, I have never seen or participated in a learning experience more powerful than [this] course.  The course has the ability to dramatically enhance the learner’s ability to produce meaningful results as a team member and as a team leader.  Not to mention, the course was great fun."
            who="Douglas Bissonette"
            role="Superintendent of Schools"
          ></Testimonials>
          {/* <!-- Top tile --> */}
        </div>
        <div class="column is-one-third">
          <Testimonials
            text="WEST is a game changer. [Throughout] the program, you deal with committed professionals from the Pluralistic team that can guide you through the symbolic dungeons on your quest to 'learn to learn' and 'trust to trust.'”
            "
            who="Rossana Fuentes-Berain"
            role="Journalist, Professor, and former World Economic Forum fellow"
          ></Testimonials>
        </div>
        <div class="column is-one-third">
          <Testimonials
            text="The ability to collaborate effectively within teams and across departmental and institutional boundaries is fundamental to my work; my participation in this course has significantly advanced my ability to do this well. I recommend this course to any doctor - or to anyone that has to work in teams."
            who="Dr. Cathleen M. Gould"
            role="Psychiatrist in Charge"
          ></Testimonials>
        </div>
        <div class="column is-one-third">
          <Testimonials
            text="There is no question that I would recommend this course as I don't see anyone coming away from this course without having learned and realizing that they are a different person. If you think you know how to lead small teams, or you want to learn,
          this is the course for you."
            who="George West"
            role="Executive Coach"
          ></Testimonials>
        </div>
        {/* <!-- This tile will take up the whole vertical space --> */}
        <div class="column is-one-third">
          <Testimonials
            text="This is a fantastic course that has changed how I lead and the results I’m getting as a leader as well as how I engage with my family, community and world. My only regret is not having done it sooner."
            who="Laura Buska"
            role="Director (Delivery Management), Microsoft"
          ></Testimonials>
        </div>
        <div class="column is-one-third">
          <Testimonials
            text="In the beginning, it was humbling and painful. In the end, the knowledge takeaway on myself, as well as
        on leadership/coordinating action, was worth every cent and time
          invested."
            who="Eric Williams"
            role="Founder, CEO (and former NFL player)"
          ></Testimonials>
        </div>
      </div>
    </>
  )
}

export default AllTestimonials
