import React from "react"
import { Link } from "gatsby"

const Resources = ({ mt }) => (
  <>
    <section style={{ marginTop: `${mt}`, marginBottom: "" }}>
      <center>
        <span class="pageTitle3">Pluralistic </span>
        <span class="pageSub2">resources</span>
      </center>
      <div
        style={{ marginTop: "58px", marginBottom: "140px" }}
        class="is-multiline columns"
      >
        <div class="column is-one-third has-text-centered">
          <img src="/images/icons/icon-MN.png" />

          <div class=" resourceItem">
            Mood <br></br>Navigator
          </div>

          <div class="centeredAlways PnBody">
            An on-demand guide to help recognize and cultivate effective moods
            in teams.
          </div>

          <button class=" resourceItemBtn">
            <Link to="/navigator">Learn More</Link>
          </button>
        </div>
        <div class="column is-one-third has-text-centered">
          {" "}
          <img src="/images/icons/icon-pubs.png" />
          <div class="  resourceItem">Books & Publications</div>
          <div class=" centeredAlways PnBody">
            Our team's writings about trust, communication, and coordination.
          </div>
          <button class="resourceItemBtn" style={{ marginTop: "46px" }}>
            <Link to="/publications">view publications</Link>
          </button>
        </div>
        <div class="column is-one-third has-text-centered">
          <img src="/images/icons/icon-blog.png" />
          <div class="resourceItem">
            Our <br />
            Blog
          </div>

          <div class="centeredAlways PnBody" style={{ marginTop: "px" }}>
            Our latest thoughts, news about Pluralistic, and upcoming events.
          </div>

          <button class="resourceItemBtn" style={{ marginTop: "48px" }}>
            <Link to="/blog">read our latest</Link>
          </button>
        </div>
        <div class="column is-one-third"></div>

        <div class="centeredAlways column is-one-third"></div>
        <div class="centeredAlways column is-one-third"></div>
        <div class="centeredAlways column is-one-third"></div>
      </div>
    </section>
  </>
)

export default Resources
