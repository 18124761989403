import React from "react"
import { Link } from "gatsby"
import Testimonials from "./Testimonials"
import AllTestimonials from "./AllTestimonials"
import Announcement from "./Announcement"

const WestSummary = ({ mt }) => (
  <>
    <section style={{ marginTop: `${mt}` }}>
      <center>
        <span class="pageTitle2">Work Effectively </span>
        <span class="pageSub2">in Small Teams</span>
      </center>

      <div style={{ marginTop: "36px" }} class="bigText">
        As teams become more virtual, distributed and diverse, our ability to
        build trust, coordinate, and communicate with each other becomes both
        more essential and more difficult. Our program,{" "}
        <strong>Working Effectively in Small Teams (WEST)</strong>, helps teams
        rapidly develop skills they need to be effective through on-line
        training, virtual learning labs, and on-demand guides.{" "}
        <Link class="gotoLink" to="https://programs.pluralisticnetworks.com/west-landing-page-48cccfee-5b01-4c94-bb72-c8aacacb5f29
">
          Learn about WEST
        </Link>
      </div>
    </section>
    <span>
      <div class="testimonial-container">
        <Testimonials
          text="Why didn't we get this course earlier in our careers?"
          who="Sue Higgins"
          role="Commander, US Navy (Retired)"
        ></Testimonials>
      </div>
    </span>

    <span>
      <div class="testimonial-container">
        <Testimonials
          text="This has been one of the most impactful courses of study I have ever had the opportunity to
          participate in."
          who="Paul Adams"
          role="Lead Financial Advisor"
        ></Testimonials>
      </div>
    </span>

    <center>
      <div class="testimonial-container" style={{ margin: "auto" }}>
        <Testimonials
          text="WEST blows away the team retreat or the ropes course. It is the most effective program I've
          found to make lasting improvements to an executive's leadership skills, particularly in a diverse,
          distributed and fast-changing workplace."
          who="Dan Hoffman"
          role="CEO"
        ></Testimonials>
      </div>
    </center>
    <center>
      <div className="announcementText" style={{ marginTop: "20px" }}>

        <Link
          className="goToLink"
          to="/contact"
          style={{ textDecoration: "underline" }}
        >
          Sign up to learn more
        </Link>
      </div>
    </center>
  </>
)

export default WestSummary
