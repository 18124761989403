import React from "react"
import { Link } from "gatsby"

function Announcement() {
  return (
    <div className="banner" id="myBanner">
      <center>
        <div className="announcementText">
          Sign up to be notified when enrollment opens
          <br />
          <Link
            className="goToLink"
            to="/west122020"
            style={{ textDecoration: "underline" }}
          >
            Join waitlist
          </Link>
        </div>
      </center>
    </div>
  )
}

export default Announcement
