import React from "react"

export const Testimonials = ({ text, who, role }) => {
  return (
    <>
      <div class="speech-bubble-container">
        <div class="speech-bubble-ds">
          <p>{text}</p>
          <div class="speech-bubble-ds__arrow"></div>
        </div>
        <div class="who">{who}</div>
        <div class="whoRole">{role}</div>
      </div>
    </>
  )
}

export default Testimonials
